import React from 'react'
import PropTypes from 'prop-types'
import { slugfy } from '@cms/utils'

import { Synonymies } from '../shared/text-styled'
import variableSubstitution from '../../utils/variableSubstitution'
import * as S from './styled'
import * as F from '../shared/fragments/exam-fragments'
import useIsBrazil from '../../utils/isBrazil'

const Exam = ({ exam, data, slug }) => {
  const isBrazil = useIsBrazil()
  const pageData = data.wordpressWpGatsbyPage.acf
  const deadline = exam && exam.prazos ? variableSubstitution(pageData.description_deadline, "XX", exam.prazos) : pageData.text_no_results
  const synonymies = exam && exam.sinonimias ? `${pageData.others_names_exam_details} ${exam.sinonimias.join(', ')}` : pageData.text_no_results
  const diseases = exam && exam.doencas ? exam.doencas.join(', ') : pageData.text_no_results
  const examPath = exam ? `/${slug}/${slugfy(exam.nome)}` : ''
  const examObj = {
    specializationProduct: exam && exam.areaEspecializacaoProduto ? isBrazil ? exam.areaEspecializacaoProduto : { areaEspecializacao: exam.areaEspecializacaoProduto } : ''
  }

  return (
    <S.Exam>
      <F.SpecializationProduct 
        exam={examObj}
        noResults={pageData.text_no_results} 
      />
      
      <S.TitleHolder>
        <S.Title>
          <S.TitleLink to={examPath}>{exam.nome}</S.TitleLink>
        </S.Title>
      </S.TitleHolder>

      {synonymies && (
        <Synonymies>
          {synonymies}
        </Synonymies>
      )}

      <S.ContentRow>
        <S.ContentColumn>
          {pageData.title_diseases_section}:
        </S.ContentColumn>

        <S.ContentColumn>
          {diseases}
        </S.ContentColumn>

      </S.ContentRow>

      <S.ContentRow>
        <S.ContentColumn>
          {pageData.title_deadline_section}:
        </S.ContentColumn>

        <S.ContentColumn>
          {deadline}
        </S.ContentColumn>
      </S.ContentRow>

      {isBrazil && exam.coberturaAns && 
        <S.AnsDiv>
          <S.AnsChip>{pageData.ans_label_exam_details}</S.AnsChip>
        </S.AnsDiv>
      }
    </S.Exam>
  )
}

Exam.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default Exam
